import React from "react";
import Grid from "./grid";
import { Paragraph, Flex } from "blocksin-system";
import KineticCanvas from "./KineticCanvas";

const RandomIcon = ({
  currentAuthor,
  textColor,
  bgColor,
  CurrentIcon,
  currentIconName,
}) => {
  return (
    <div
      style={{
        backgroundColor: bgColor,
        color: textColor,
      }}
      className="RandomIcon"
    >
      <KineticCanvas />

      <div className="IconWrapper">
        <Grid
          style={{
            width: "100%",
            height: "auto",
            maxHeight: "76vh",
            position: "absolute",
            zIndex: 0,
            opacity: "0.3",
            margin: "auto",
          }}
          className="Grid"
        />
        <CurrentIcon
          style={{
            width: "100%",
            height: "auto",
            maxHeight: "76vh",
            zIndex: 1,
          }}
        />
        <Flex
          style={{
            top: "100%",
            marginTop: "32px",
            position: "absolute",
          }}
          align="center"
          gap={200}
        >
          <Paragraph size="large">{`<${currentIconName} />`}</Paragraph>
          <a
            className="link"
            href={currentAuthor.URL}
            target="_blank"
            rel="noreferrer"
          >
            {/* by {currentAuthor.name} */}
          </a>
        </Flex>
      </div>
    </div>
  );
};

export default RandomIcon;
