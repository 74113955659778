import React, { useState, useEffect, useRef } from "react";
import { Helmet } from "react-helmet";
import RandomIcon from "./RandomIcon";
import { fabric } from "fabric";

import "./App.scss";
import {
  Button,
  Toast,
  Flex,
  Paragraph,
  Input,
  IconButton,
  ScrollArea,
  DropdownMenu,
  Slider,
} from "blocksin-system";
import SebikoLogo from "./SebikoLogo";
import * as Dialog from "@radix-ui/react-dialog";
import { CopyIcon, Cross2Icon, PlusIcon } from "sebikostudio-icons";
import ReactDOMServer from "react-dom/server";
import { HexColorPicker } from "react-colorful";
import RequestIcon from "./RequestIcon";

function App() {
  const [showToast, setShowToast] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [openRequest, setOpenRequest] = useState(false);

  const [searchTerm, setSearchTerm] = useState("");
  const [text, setText] = useState("Error");
  const [strokeWidth, setStrokeWidth] = useState([1]);
  const updateRef = useRef();

  const handleSliderChange = (value) => {
    if (updateRef.current) {
      cancelAnimationFrame(updateRef.current);
    }
    updateRef.current = requestAnimationFrame(() => {
      setStrokeWidth(value);
    });
  };

  const copyToClipboard = () => {
    navigator.clipboard
      .writeText("npm i sebikostudio-icons")
      .then(() => {
        setShowToast(true);
        setText("Command copied to clipboard.");
      })
      .catch((err) => {
        console.error("Could not copy text: ", err);
        setText("");
      });
  };

  const openNpmPage = () => {
    window.open("https://www.npmjs.com/package/sebikostudio-icons", "_blank");
  };

  const colors = ["#2F4DC6", "#282A34", "#F55F55", "#EBEDEF"];
  const [bgColor, setBgColor] = useState(() => getRandomColor());

  function getRandomColor() {
    return colors[Math.floor(Math.random() * colors.length)];
  }

  const textColor =
    bgColor === "#282A34" || bgColor === "#2F4DC6" ? "var(--white)" : "inherit";

  const setDarkmode =
    bgColor === "#282A34" || bgColor === "#2F4DC6" ? "darkmode" : "";

  const [currentIconIndex, setCurrentIconIndex] = useState(0);
  const [iconNames, setIconNames] = useState([]);
  const [iconComponents, setIconComponents] = useState({});
  const [iconColor, setIconColor] = useState("#000000");
  const [iconColor2, setIconColor2] = useState("#000000");
  const [dropdownVisible, setDropdownVisible] = useState(false);

  const authors = [
    // {
    //   name: "Rob",
    //   URL: "https://google.com",
    //   icons: ["BoxIcon", "BellIcon"],
    // },
    // {
    //   name: "Alice",
    //   URL: "https://example.com",
    //   icons: ["HeartIcon", "StarIcon"],
    // },
  ];

  useEffect(() => {
    const loadIcons = async () => {
      const icons = await import("sebikostudio-icons");
      const filteredIcons = {};
      const iconNames = Object.keys(icons).filter((icon) =>
        icon.endsWith("Icon")
      );

      iconNames.forEach((icon) => {
        filteredIcons[icon] = icons[icon];
      });

      setIconComponents(filteredIcons);
      setIconNames(iconNames);
      setCurrentIconIndex(getRandomIconIndex(null, iconNames.length));
    };
    loadIcons();
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIconIndex((prevIndex) =>
        getRandomIconIndex(prevIndex, iconNames.length)
      );
      setBgColor(getRandomColor());
    }, 2500);
    return () => clearInterval(interval);
    // eslint-disable-next-line
  }, [iconNames]);

  function getRandomIconIndex(prevIndex, length) {
    if (length === 0) return 0;
    let newIndex;
    do {
      newIndex = Math.floor(Math.random() * length);
    } while (newIndex === prevIndex);
    return newIndex;
  }

  if (iconNames.length === 0) return null;

  const currentIconName = iconNames[currentIconIndex];
  const CurrentIcon = iconComponents[currentIconName];

  const getAuthorForIcon = (iconName) => {
    for (const author of authors) {
      if (author.icons.includes(iconName)) {
        return author;
      }
    }
    return {
      name: "Seb",
      URL: "https://www.linkedin.com/in/sebastiangrochocki/",
    };
  };

  const currentAuthor = getAuthorForIcon(currentIconName);

  const filteredIconNames = iconNames.filter((name) => {
    const IconComponent = iconComponents[name];
    if (!IconComponent) return false;
    const ariaLabel =
      ReactDOMServer.renderToStaticMarkup(<IconComponent />).match(
        /aria-label="([^"]*)"/
      )?.[1] || "";
    const ariaLabels = ariaLabel
      .split(",")
      .map((label) => label.trim().toLowerCase());
    return (
      name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      ariaLabels.some((label) => label.includes(searchTerm.toLowerCase()))
    );
  });

  const copyIconAsPng = async (
    IconComponent,
    iconName,
    gradientColors,
    strokeWidth = 1
  ) => {
    const size = 20; // Original size of the icon
    const scale = 20; // Scaling factor for higher pixel density
    const scaledSize = size * scale; // Scaled size for canvas

    // Render the SVG string without explicit stroke or fill color
    const svgString = ReactDOMServer.renderToString(
      <IconComponent width={size} height={size} style={{ strokeWidth }} />
    );

    const svg = `
      <svg xmlns="http://www.w3.org/2000/svg" width="${size}" height="${size}" viewBox="0 0 ${size} ${size}">
        ${svgString}
      </svg>
    `;

    fabric.loadSVGFromString(svg, (objects, options) => {
      const fabricCanvas = new fabric.Canvas(null, {
        width: scaledSize,
        height: scaledSize,
      });

      // Create a gradient rectangle
      const gradient = new fabric.Gradient({
        type: "linear",
        gradientUnits: "percentage",
        coords: {
          x1: 0,
          y1: 0,
          x2: 1,
          y2: 1,
        },
        colorStops: [
          { offset: 0, color: gradientColors[0] },
          { offset: 1, color: gradientColors[1] },
        ],
      });

      const gradientRect = new fabric.Rect({
        width: scaledSize,
        height: scaledSize,
        fill: gradient,
      });

      fabricCanvas.add(gradientRect);

      // Center and scale the objects uniformly
      const scaledObjects = objects.map((obj) => {
        obj.set({
          left: obj.left * scale,
          top: obj.top * scale,
          scaleX: scale,
          scaleY: scale,
          originX: "left",
          originY: "top",
        });
        return obj;
      });

      const maskGroup = new fabric.Group(scaledObjects, {
        originX: "center",
        originY: "center",
        selectable: false,
        globalCompositeOperation: "destination-in",
      });
      fabricCanvas.add(maskGroup);
      fabricCanvas.renderAll();

      const htmlCanvas = document.createElement("canvas");
      htmlCanvas.width = scaledSize;
      htmlCanvas.height = scaledSize;
      const ctx = htmlCanvas.getContext("2d");

      const dataURL = fabricCanvas.toDataURL({
        format: "png",
        multiplier: 1,
      });

      const img = new Image();
      img.onload = () => {
        ctx.drawImage(img, 0, 0, scaledSize, scaledSize);
        htmlCanvas.toBlob((blob) => {
          const item = new ClipboardItem({ "image/png": blob });
          navigator.clipboard
            .write([item])
            .then(async () => {
              setShowToast(true);
              setText("Icon copied to clipboard.");
              // console.log(
              //   `Icon copied to clipboard with gradient ${gradientColors} ${iconName}`
              // );

              // Send data to Netlify function
              // const iconName1 = iconName; // Assuming IconComponent has a name property
              // const timestamp = new Date().toISOString();

              // await fetch("/.netlify/functions/submit-icon-click", {
              //   method: "POST",
              //   headers: {
              //     "Content-Type": "application/json",
              //   },
              //   body: JSON.stringify({ iconName1, gradientColors, timestamp }),
              // });

              // Submit to Google Form
              const formId =
                "1FAIpQLSc6rf8qp4RxH-9wxzwccLCcjW03FtBKeg2FyHasXXfA305X_Q";
              const entryId = "entry.1829251975";
              const entryDate = "entry.1031412175";
              const currentDate = new Date().toISOString().split("T")[0]; // Get current date in YYYY-MM-DD format

              const formData = new URLSearchParams();
              formData.append(entryId, iconName);
              formData.append(entryDate, currentDate);

              try {
                await fetch(
                  `https://docs.google.com/forms/d/e/${formId}/formResponse`,
                  {
                    method: "POST",
                    headers: {
                      "Content-Type": "application/x-www-form-urlencoded",
                    },
                    body: formData,
                  }
                );
                console.log("Form submitted successfully.");
              } catch (error) {
                console.error("Error submitting form:", error);
              }
            })
            .catch((err) => {
              console.error("Failed to write to clipboard: ", err);
            });
        }, "image/png");
      };
      img.src = dataURL;
    });
  };

  return (
    <>
      <div className={`App ${openDialog && "blur"}`}>
        <Helmet>
          <title>
            Sebikostudio Icons - Beautiful Icons for React Developers and
            Product Designers
          </title>
          <meta
            name="description"
            content="Install sebikostudio-icons from NPM. Enhance your React projects with beautiful, customizable icons perfect for product designers and developers."
          />
          <meta
            property="og:title"
            content="Sebikostudio Icons - Beautiful Icons for React Developers and Product Designers"
          />
          <meta
            property="og:description"
            content="Install sebikostudio-icons from NPM. Enhance your React projects with beautiful, customizable icons perfect for product designers and developers."
          />
          <meta
            property="og:image"
            content="https://icons.sebikostudio.com/poster.jpg"
          />
          <meta property="og:image:width" content="1200" />
          <meta property="og:image:height" content="627" />
          <meta
            property="og:url"
            content="https://www.npmjs.com/package/sebikostudio-icons"
          />
          <meta name="twitter:card" content="summary_large_image" />
        </Helmet>
        <Flex
          customClass="logoWrapper"
          align="center"
          gap={200}
          style={{ color: textColor }}
        >
          <SebikoLogo />
          <Paragraph weight="bold">{iconNames.length} icons ♥</Paragraph>
          <div className={`nomobile ${setDarkmode}`}>
            <Button
              size="small"
              variant="ghost"
              onClick={() => setOpenRequest(true)}
            >
              Request
            </Button>
          </div>
          <div
            className={`mobile ${setDarkmode}`}
            style={{ marginLeft: "auto" }}
          >
            <Button
              size="small"
              variant="ghost"
              onClick={() => setOpenDialog(true)}
            >
              Search
            </Button>
          </div>
        </Flex>
        <RequestIcon openDialog={openRequest} setOpenDialog={setOpenRequest} />
        <Flex customClass={`btnGroup ${setDarkmode}`} gap={200}>
          <div className="nomobile">
            <Button
              size="small"
              variant="ghost"
              onClick={() => setOpenDialog(true)}
            >
              Search
            </Button>
          </div>
          <Button size="small" variant="ghost" onClick={openNpmPage}>
            <CurrentIcon style={{ strokeWidth: "1.25px" }} />
            NPM page
          </Button>
          <Button size="small" variant="outline" onClick={copyToClipboard}>
            <CurrentIcon style={{ strokeWidth: "1.25px" }} />
            npm i sebikostudio-icons
          </Button>
        </Flex>

        <RandomIcon
          textColor={textColor}
          bgColor={bgColor}
          setBgColor={setBgColor}
          getRandomColor={getRandomColor}
          CurrentIcon={CurrentIcon}
          currentIconName={currentIconName}
          currentAuthor={currentAuthor}
        />

        <Dialog.Root open={openDialog} onOpenChange={setOpenDialog}>
          <Dialog.Portal>
            <Dialog.Overlay className="DialogOverlay" />
            <Dialog.Content className="DialogContent">
              <Dialog.Title
                style={{
                  opacity: "0",
                  height: "0",
                  width: "0",
                  overflow: "hidden",
                }}
              >
                Search for icons..
              </Dialog.Title>
              <Flex
                direction="column"
                align="center"
                justify="center"
                gap={400}
                style={{ overflow: "hidden", paddingTop: "var(--size-200)" }}
              >
                <Flex
                  gap={200}
                  customClass="Group"
                  align="center"
                  justify="center"
                  fluid
                >
                  <Input
                    type="text"
                    placeholder="Search icons..."
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    fluid
                    label="Search"
                  />
                  <Flex gap={100} customClass="colorPicker">
                    <DropdownMenu>
                      <DropdownMenu.Trigger asChild>
                        <div
                          className="colorBox"
                          style={{
                            background: iconColor,
                          }}
                        ></div>
                      </DropdownMenu.Trigger>
                      <DropdownMenu.Content className="DropdownMenuContent noPadding">
                        <HexColorPicker
                          color={iconColor}
                          onChange={setIconColor}
                        />
                      </DropdownMenu.Content>
                    </DropdownMenu>
                    {dropdownVisible ? (
                      <DropdownMenu>
                        <DropdownMenu.Trigger asChild>
                          <div
                            className="colorBox"
                            style={{
                              background: iconColor2,
                            }}
                          ></div>
                        </DropdownMenu.Trigger>
                        <DropdownMenu.Content className="DropdownMenuContent noPadding">
                          <HexColorPicker
                            color={iconColor2}
                            onChange={setIconColor2}
                          />
                        </DropdownMenu.Content>
                      </DropdownMenu>
                    ) : (
                      <IconButton
                        size="medium"
                        variant="ghost"
                        style={{ strokeWidth: "1.25px" }}
                        onClick={() => setDropdownVisible(true)}
                      >
                        <PlusIcon />
                      </IconButton>
                    )}
                  </Flex>
                </Flex>
                <Slider
                  min={0.75}
                  max={2}
                  step={0.25}
                  value={strokeWidth}
                  onValueChange={handleSliderChange}
                />
                <ScrollArea style={{ maxHeight: "auto", height: "auto" }}>
                  <Flex wrap="wrap" customClass="iconGrid" gap={100}>
                    {filteredIconNames.map((iconName) => {
                      const IconComponent = iconComponents[iconName];
                      // const ariaLabel =
                      //   ReactDOMServer.renderToStaticMarkup(
                      //     <IconComponent />
                      //   ).match(/aria-label="([^"]*)"/)?.[1] || "";

                      return (
                        <button
                          key={iconName}
                          className="iconItem"
                          // style={{ color: textColor }}
                          onClick={() =>
                            copyIconAsPng(
                              IconComponent,
                              iconName,
                              [
                                iconColor,
                                dropdownVisible ? iconColor2 : iconColor,
                              ],
                              strokeWidth
                            )
                          }
                          onTouchEnd={() =>
                            copyIconAsPng(
                              IconComponent,
                              iconName,
                              [
                                iconColor,
                                dropdownVisible ? iconColor2 : iconColor,
                              ],
                              strokeWidth
                            )
                          }
                        >
                          <div
                            style={{
                              background: `linear-gradient(135deg, ${iconColor}, ${
                                dropdownVisible ? iconColor2 : iconColor
                              })`,
                              mask: `url('data:image/svg+xml;utf8,${encodeURIComponent(
                                ReactDOMServer.renderToString(
                                  <IconComponent
                                    width={40}
                                    height={40}
                                    color="currentColor"
                                    style={{ strokeWidth: strokeWidth[0] }} // Use the stroke width from state
                                  />
                                )
                              )}') no-repeat center/40px 40px`,
                              // maskSize: "40px 40px",
                              width: "40px",
                              height: "40px",
                            }}
                          ></div>
                          <Paragraph size="small">{iconName}</Paragraph>
                          {/* <p>{ariaLabel}</p> */}
                          <CopyIcon
                            className="copy"
                            style={{ color: textColor }}
                          />
                        </button>
                      );
                    })}
                  </Flex>
                </ScrollArea>
              </Flex>
              <Flex customClass="closeButton">
                <Dialog.Close asChild>
                  <IconButton size="small" variant="ghost">
                    <Cross2Icon />
                  </IconButton>
                </Dialog.Close>
              </Flex>
            </Dialog.Content>
          </Dialog.Portal>
        </Dialog.Root>
      </div>
      <Toast
        showToast={showToast}
        setShowToast={setShowToast}
        simple={true}
        text={text}
        time={300}
        showAgain={false}
      />
    </>
  );
}

export default App;
