import React, { useState } from "react";
import {
  Flex,
  Button,
  Input,
  Heading,
  Paragraph,
  Slider,
  Separator,
} from "blocksin-system";
import Lottie from "lottie-react";
import animationData from "./assets/confetti.json";
import * as Dialog from "@radix-ui/react-dialog";

function RequestIcon({ openDialog, setOpenDialog }) {
  const [description, setDescription] = useState("");
  const [submit, setSubmit] = useState(false);
  const [showAnimation, setShowAnimation] = useState(false);
  const [like, setLike] = useState(3);

  const handleSubmit = (event) => {
    event.preventDefault();

    const googleFormUrl =
      "https://docs.google.com/forms/u/0/d/e/1FAIpQLSeSPNq-g7kUtTLkUZ5xKUO_pDXN2f_09s2KpBwhZsHyCyv2PQ/formResponse"; // Replace with your Google Form URL
    const descriptionField = "entry.301507512"; // Replace with your description field ID from Google Form
    const likeField = "entry.1961021180"; // Replace with your like field ID from Google Form

    const formData = new URLSearchParams();
    formData.append(descriptionField, description);
    formData.append(likeField, like);

    fetch(googleFormUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: formData.toString(),
      mode: "no-cors",
    })
      .then(() => {
        setSubmit(true);
        setDescription("");
        setShowAnimation(true);
      })
      .catch(() => {
        alert("There was an error submitting the form.");
      });
  };

  const getLike = (value) => {
    switch (value) {
      case 1:
        return "so so";
      case 2:
        return "it's ok";
      case 3:
        return "like it!";
      case 4:
        return "pretty great!";
      case 5:
        return "love it!";
      default:
        return "so so";
    }
  };

  const getEmoji = (value) => {
    switch (value) {
      case 1:
        return "😢";
      case 2:
        return "🙂";
      case 3:
        return "😊";
      case 4:
        return "🤩";
      case 5:
        return "😍";
      default:
        return "";
    }
  };

  return (
    <Dialog.Root open={openDialog} onOpenChange={setOpenDialog}>
      <Dialog.Portal>
        <Dialog.Overlay className="DialogOverlay DialogOverlayRequest" />
        <Dialog.Content className="DialogContent DialogContentRequest">
          <Dialog.Title
            style={{
              opacity: "0",
              height: "0",
              width: "0",
              overflow: "hidden",
            }}
          >
            Request an Icon
          </Dialog.Title>
          <Flex
            customClass="Request"
            align="center"
            justify="center"
            direction="column"
            gap={200}
            fluid
          >
            {showAnimation && (
              <Lottie
                className="lottie"
                animationData={animationData}
                autoplay={true}
                loop={false}
                onComplete={() => setShowAnimation(false)}
                rendererSettings={{ preserveAspectRatio: "xMidYMid slice" }}
              />
            )}
            {!submit && (
              <Flex direction="column" customClass="TextBox" fluid gap={100}>
                <Heading weight="bold" level={3}>
                  Request an Icon
                </Heading>
                <Paragraph>Describe an icon or add keywords.</Paragraph>
                <Separator />
              </Flex>
            )}
            {submit ? (
              <Paragraph>Thanks, I will get to work soon</Paragraph>
            ) : (
              <Flex direction="column" gap={300} fluid align="center">
                <Flex direction="column" gap={200} fluid>
                  <Paragraph>
                    How much do you like the current library?
                  </Paragraph>
                  <Flex
                    align="center"
                    customClass="RequestSlider"
                    gap={200}
                    fluid
                  >
                    <Slider
                      value={[like]}
                      onValueChange={(value) => setLike(value[0])}
                      max={5}
                      step={1}
                      min={1}
                      fluid
                    />
                  </Flex>
                  <Flex gap={100} align="center" justify="center">
                    <Heading level={4}>{getLike(like)}</Heading>
                    <Heading level={3}>{getEmoji(like)}</Heading>
                  </Flex>
                  <Separator />
                </Flex>
                <form onSubmit={handleSubmit}>
                  <Input
                    type="text"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    placeholder="Enter a description or keyword"
                    required
                    fluid
                    label="Description"
                  />

                  <Button type="submit" fluid>
                    Submit
                  </Button>
                </form>
              </Flex>
            )}
          </Flex>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
}

export default RequestIcon;
